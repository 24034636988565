import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SpecificRentalPage = () => {
  const [rentalInfo, setRentalInfo] = useState({});
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const token = localStorage.getItem("token");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const [ratings, setRatings] = useState(0);
  const userId = userData.unique;
  const [amenities, setAmenities] = useState([]);
  const rentalId = localStorage.getItem("specificRentalId");
  const [selectedPhoto, setSelectedPhoto] = useState(null); // State to track selected photo
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false); // State to track if the photo modal is open

  useEffect(() => {
    // Fetch rental information
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/rentals/specific/${rentalId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setRentalInfo(response.data.response);
        // Fetch amenities after setting rentalInfo
        fetchRatings(response.data.response.property_id);
        fetchAmenities(response.data.response.property_id);
      })
      .catch((error) => {
        console.error("Error fetching rental information:", error);
      });

    // Fetch photos
    axios
      .get(`https://backend.inyumba.co.ke/iNyumba/photos/fetch/${rentalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPhotos(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching photos:", error);
      });

    // Fetch videos
    axios
      .get(`https://backend.inyumba.co.ke/iNyumba/videos/fetch/${rentalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setVideos(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching videos:", error);
      });
  }, [rentalId, token]);

  const fetchAmenities = (propertyId) => {
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/amenities/fetch/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setAmenities(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching amenities:", error);
      });
  };

  const handleAddReservation = () => {
    // Add reservation
    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/user/reservations/add/${userId}/${rentalId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
      })
      .catch((error) => {
        console.error("Error adding reservation:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleAddFavourites = () => {
    // Add to favorites
    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/favorite/users/${userId}/favorites/${rentalId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response.data.response);
      })
      .catch((error) => {
        console.error("Error adding to favorites:", error);
        toast.error(error.response.data.response);
      });
  };

  // Function to fetch ratings
  const fetchRatings = (rentalId) => {
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/comments/average-ratings/${rentalId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setRatings(response.data.response || 0); // Update ratings state variable
      })
      .catch((error) => {
        console.error("Error fetching ratings:", error);
      });
  };

  // Inside the SpecificRentalPage component

  // Function to handle opening the modal with selected photo
  const openPhotoModal = (photo) => {
    setSelectedPhoto(photo);
    setIsPhotoModalOpen(true);
  };

  // Function to close the modal
  const closePhotoModal = () => {
    setSelectedPhoto(null);
    setIsPhotoModalOpen(false);
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        {/* Rental Information */}
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
          {/* Rental Image */}
          <div className="mb-8">
            <img
              src={rentalInfo.photo}
              alt="Rental Photo"
              className="w-full h-72 object-cover cursor-pointer rounded-lg shadow-lg"
            />
            <div className="mt-4 space-y-2">
              <p className="text-xl font-semibold text-gray-900">
                House Type: {rentalInfo.house_type}
              </p>
              <p className="text-lg text-gray-700">
                <strong>Rent:</strong> {rentalInfo.rent_price}
              </p>
              <p className="text-lg text-gray-700">
                <strong>Description:</strong> {rentalInfo.description}
              </p>
              <p className="text-lg text-gray-700">
                <strong>House Number:</strong> {rentalInfo.house_number}
              </p>
              <p className="text-lg text-gray-700">
                <strong>Vacancy:</strong>{" "}
                <span
                  className={`font-semibold ${
                    rentalInfo.status === "Available"
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {rentalInfo.status}
                </span>
              </p>
              <p className="text-lg text-gray-700">
                <strong>Ratings:</strong> {ratings || 0}/5 ⭐
              </p>
            </div>
          </div>

          {/* Amenities Section */}
          <h2 className="text-2xl font-semibold text-gray-900 mb-3">
            Amenities
          </h2>
          <div className="flex flex-wrap gap-2">
            {amenities.map((amenity, index) => (
              <span
                key={index}
                className="bg-gray-100 text-gray-800 text-sm font-medium px-3 py-1 rounded-md"
              >
                {amenity.amenity_name}
              </span>
            ))}
          </div>

          {/* Photos Section */}
          <div className="mt-8">
            <h2 className="text-2xl font-semibold text-gray-900 mb-3">
              Photos
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              {photos.map((photo, index) => (
                <div
                  key={index}
                  className="cursor-pointer transition-all duration-300 transform hover:scale-105 hover:shadow-xl"
                  onClick={() => openPhotoModal(photo)}
                >
                  <img
                    src={photo.photo_url}
                    alt={`Rental Photo ${index}`}
                    className="w-full h-40 object-cover rounded-lg shadow-md"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Photo Modal */}
        {selectedPhoto && (
          <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 transition-opacity duration-300"
            onClick={closePhotoModal}
          >
            <div className="relative max-w-screen-lg p-4">
              <img
                src={selectedPhoto.photo_url}
                alt="Rental Photo"
                className="w-full max-h-[90vh] object-contain rounded-lg shadow-lg"
              />
              <button
                className="absolute top-4 right-4 bg-white text-gray-900 rounded-full p-2 shadow-md hover:bg-gray-200 transition"
                onClick={closePhotoModal}
                aria-label="Close Modal"
              >
                ✕
              </button>
            </div>
          </div>
        )}

        {/* Videos Section */}
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-2 text-gray-900">Videos</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {videos.map((video, index) => (
              <div key={index} className="w-full">
                <iframe
                  title={`Rental Video ${index}`}
                  src={video.video_url}
                  className="w-full aspect-video rounded-lg shadow-md"
                  allowFullScreen
                ></iframe>
              </div>
            ))}
          </div>
        </div>

        {/* Action Buttons */}
        {rentalInfo.status === "OPEN" && (
          <div className="mt-8 flex space-x-4">
            <button
              onClick={handleAddReservation}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
            >
              Add Reservation
            </button>
            <button
              onClick={handleAddFavourites}
              className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-3 px-6 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105"
            >
              Add to Favorites
            </button>
          </div>
        )}
      </div>
    </AuthGuard>
  );
};

export default SpecificRentalPage;
