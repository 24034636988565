import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSignOutAlt,
  faHeart,
  faCreditCard,
  faWallet,
  faBell,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { AuthGuard } from "../App";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PropertyMarketPlace = () => {
  const [properties, setProperties] = useState([]);
  const [locations, setLocations] = useState([]);
  const [houseTypes, setHouseTypes] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedHouseType, setSelectedHouseType] = useState("");
  const [rentals, setRentals] = useState([]);
  const userDataString = localStorage.getItem("user");
  const [propertyRatings, setPropertyRatings] = useState({});
  const [rentalRatings, setRentalRatings] = useState({});
  const [notificationsCount, setNotificationsCount] = useState(0);
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;
  const [webUrl, setWebUrl] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        // Fetch locations
        const locationResponse = await axios.get(
          "https://backend.inyumba.co.ke/iNyumba/viewlocations",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLocations(locationResponse.data.response);

        // Fetch house types
        const houseTypeResponse = await axios.get(
          "https://backend.inyumba.co.ke/iNyumba/viewhousetype",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setHouseTypes(houseTypeResponse.data.response);

        try {
          const token = localStorage.getItem("token");
          // Fetch rentals
          const rentalResponse = await axios.get(
            "https://backend.inyumba.co.ke/iNyumba/admin/rentals/filterrental",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setRentals(rentalResponse.data.response);
        } catch (error) {
          console.error("Error fetching rentals:", error);
        }

        // Fetch properties
        const propertyResponse = await axios.get(
          "https://backend.inyumba.co.ke/iNyumba/admin/properties/records",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setProperties(propertyResponse.data.response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const handleHouseTypeChange = (event) => {
    setSelectedHouseType(event.target.value);
  };

  const handleResetFilters = () => {
    setSelectedLocation("");
    setSelectedHouseType("");
  };

  const handleFavoritesClick = () => {
    // Action to perform when Favorites is clicked
    navigate("/user/favorite");
  };

  const handlePropertyClick = (PropertyId) => {
    // Handle property click
    localStorage.setItem("specificPropertyId", PropertyId);
    navigate("/user/specificproperty");
  };

  const handleRentalClick = (RentalId) => {
    // Handle rental click
    localStorage.setItem("specificRentalId", RentalId);
    navigate("/user/specificrental");
  };

  const handleBillPaymentsClick = () => {
    // Action to perform when Bill Payments is clicked
    navigate("/user/bills");
  };

  const handleApplyFilters = () => {
    // Check if either selectedLocation or selectedHouseType is present
    if (selectedLocation || selectedHouseType) {
      // Fetch rentals based on filters
      const queryParams = {};
      if (selectedLocation) {
        queryParams.location = selectedLocation;
      }
      if (selectedHouseType) {
        queryParams.house_type = selectedHouseType;
      }

      const token = localStorage.getItem("token");
      axios
        .get(
          "https://backend.inyumba.co.ke/iNyumba/admin/rentals/filterrental",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: queryParams,
          }
        )
        .then((response) => {
          setRentals(response.data.response); // Assuming response contains filtered rentals
        })
        .catch((error) => {
          console.error("Error fetching filtered rentals:", error);
        });
    } else {
      // Fetch all rentals without any filters
      const token = localStorage.getItem("token");
      axios
        .get(
          "https://backend.inyumba.co.ke/iNyumba/admin/rentals/filterrental",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setRentals(response.data.response); // Assuming response contains all rentals without filters
        })
        .catch((error) => {
          console.error("Error fetching all rentals:", error);
        });
    }
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <div className="container mx-auto px-4 py-8">
          {/* Sticky Navbar */}
          <p className="text-1xl font-semibold mb-3">
            iNyumba Property Market Place
          </p>
          <div className="flex flex-wrap justify-center gap-6 p-4">
            {/* Bill Payments Card */}
            <div
              className="flex flex-col items-center bg-blue-600 text-white shadow-lg rounded-2xl p-6 w-60 cursor-pointer hover:bg-blue-700 transition-all transform hover:scale-105"
              onClick={handleBillPaymentsClick}
            >
              <div className="flex items-center justify-center w-20 h-20 bg-blue-500 rounded-full mb-4">
                <FontAwesomeIcon icon={faCreditCard} className="text-4xl" />
              </div>
              <h3 className="text-lg font-semibold">Bill Payments</h3>
              <p className="text-sm text-gray-200 mt-1 text-center">
                Pay your bills effortlessly.
              </p>
            </div>

            {/* Favorites Card */}
            <div
              className="flex flex-col items-center bg-green-600 text-white shadow-lg rounded-2xl p-6 w-60 cursor-pointer hover:bg-green-700 transition-all transform hover:scale-105"
              onClick={handleFavoritesClick}
            >
              <div className="flex items-center justify-center w-20 h-20 bg-green-500 rounded-full mb-4">
                <FontAwesomeIcon icon={faHeart} className="text-4xl" />
              </div>
              <h3 className="text-lg font-semibold">Favorites</h3>
              <p className="text-sm text-gray-200 mt-1 text-center">
                View your favorite properties.
              </p>
            </div>
          </div>

          {/* Centered Heading */}
          <div className="text-center">
            <p className="text-3xl font-bold text-gray-800 mb-6">Properties</p>
          </div>

          {/* Scrollable Container */}
          <div className="flex overflow-x-auto space-x-6 p-4 scrollbar-hide">
            {properties.map((property) => (
              <div
                key={property.id}
                className="flex-shrink-0 bg-white shadow-lg rounded-xl p-5 w-72 cursor-pointer hover:shadow-xl transition-all transform hover:scale-105"
                onClick={() => handlePropertyClick(property.property_id)}
              >
                {/* Property Image */}
                <img
                  src={property.photo}
                  alt={property.title}
                  className="w-full h-40 object-cover rounded-lg mb-3"
                />

                {/* Property Details */}
                <h3 className="text-lg font-semibold text-gray-900 mb-1">
                  {property.title}
                </h3>
                <p className="text-sm text-gray-600 mb-2">
                  {property.description}
                </p>
                <p className="text-sm text-gray-500">
                  📍 County: {property.county}
                </p>
                <p className="text-sm text-gray-500">
                  📍 Location: {property.location}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Centered heading */}
        {/* Centered Heading */}
        <div className="text-center">
          <p className="text-3xl font-bold text-gray-800 mb-6">Rentals</p>
        </div>

        {/* Filter Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-center bg-gray-100 p-6 rounded-lg shadow-md">
          {/* Location Dropdown */}
          <select
            value={selectedLocation}
            onChange={handleLocationChange}
            className="p-3 border border-gray-300 rounded-lg w-full text-gray-700 focus:ring-2 focus:ring-blue-400"
          >
            <option key="default" value="">
              Select Location
            </option>
            {locations.map((location, index) => (
              <option key={index} value={location}>
                {location}
              </option>
            ))}
          </select>

          {/* House Type Dropdown */}
          <select
            value={selectedHouseType}
            onChange={handleHouseTypeChange}
            className="p-3 border border-gray-300 rounded-lg w-full text-gray-700 focus:ring-2 focus:ring-blue-400"
          >
            <option key="default" value="">
              Select House Type
            </option>
            {houseTypes.map((houseType, index) => (
              <option key={index} value={houseType}>
                {houseType}
              </option>
            ))}
          </select>

          {/* Buttons Section */}
          <div className="flex space-x-4 justify-center">
            <button
              onClick={handleResetFilters}
              className="bg-gray-300 text-gray-700 px-5 py-2 rounded-lg font-semibold hover:bg-gray-400 transition"
            >
              Reset
            </button>
            <button
              onClick={handleApplyFilters}
              className="bg-blue-500 text-white px-5 py-2 rounded-lg font-semibold hover:bg-blue-600 transition"
            >
              Apply
            </button>
          </div>
        </div>

        {/* Rentals Section */}
        <div className="text-center">
          <p className="text-3xl font-bold text-gray-800 mb-6">
            Available Rentals
          </p>
        </div>

        {/* Rentals Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-4">
          {rentals.map((rental) => (
            <div
              key={rental.rental_id}
              className="bg-white shadow-lg rounded-xl overflow-hidden cursor-pointer transition hover:shadow-2xl"
              onClick={() => handleRentalClick(rental.rental_id)}
            >
              {/* Image */}
              <img
                src={rental.photo}
                alt={rental.property_name}
                className="w-full h-40 object-cover"
              />

              {/* Rental Details */}
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-900">
                  {rental.property_name}
                </h3>
                <p className="text-sm text-gray-600 mb-2">
                  {rental.description}
                </p>

                <div className="text-sm text-gray-700">
                  <p>
                    <span className="font-semibold">House Type:</span>{" "}
                    {rental.house_type}
                  </p>
                  <p>
                    <span className="font-semibold">Rent Price:</span>
                    <span className="text-green-600 font-bold">
                      {" "}
                      {rental.rent_price}
                    </span>
                  </p>
                  <p>
                    <span className="font-semibold">Location:</span>{" "}
                    {rental.location}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </AuthGuard>
  );
};

export default PropertyMarketPlace;
