import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSignOutAlt,
  faMoneyCheck,
  faMoneyBill,
  faArrowUp,
  faTag,
  faArrowDown,
  faDoorOpen,
  faWrench,
  faHome,
  faHistory,
  faFileInvoice,
  faTint,
  faBell,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons";
import { AuthGuard } from "../App";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MyApartment = () => {
  const userDataString = localStorage.getItem("user");
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false); // To toggle notifications dropdown
  const [notifications, setNotifications] = useState([]);
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;
  const [webUrl, setWebUrl] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userName = userData?.fullName || "User";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");

        // Ensure token exists before making request
        if (!token) {
          console.error("Token is missing.");
          return;
        }

        // Fetch notifications count
        const notificationsResponse = await axios.get(
          "https://backend.inyumba.co.ke/iNyumba/inappnotifications/viewallnotifications",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Update state with notifications count
        setNotificationsCount(notificationsResponse.data.response.length);
        setNotifications(notificationsResponse.data.response);
      } catch (error) {
        console.error("Error fetching notifications:", error);
        // You can also set an error state if you want to display error messages
      }
    };

    fetchData();
  }, []); // Empty dependency array, runs only on component mount

  // Toggle Notifications Dropdown
  const toggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const handleBillPaymentsClick = () => {
    // Action to perform when Bill Payments is clicked
    navigate("/user/bills");
  };

  const handleVacatingClick = () => {
    // Action to perform when Vacating is clicked
    navigate("/user/viewvacate");
  };

  const handleWaterbillClick = () => {
    // Action to perform when Waterbill is clicked
    navigate("/user/waterbill");
  };

  const handleReservationClick = () => {
    // Action to perform when Reservation is clicked
    navigate("/user/reservation");
  };

  const handleMaintenanceRequestClick = () => {
    // Action to perform when MaintenanceRequest is clicked
    navigate("/user/maintenancerequest");
  };

  const handlePreviousReservationsClick = () => {
    // Action to perform when PreviousReservations is clicked
    navigate("/user/previousreservation");
  };

  const handleProfileClick = () => {
    navigate("/user/profile");
  };

  const handleLogoutClick = () => {
    navigate("/logout");
  };

  const handleSettlementRecords = () => {
    navigate("/user/settlement");
  };

  const handleWithdrawalsClick = () => {
    // Action to perform when Withdrawals is clicked
    navigate("/user/withdrawals");
  };

  const handleAgencyFeeClick = () => {
    // Action to perform when Agency Fee is clicked
    navigate("/user/agencyviewingfee");
  };

  const handlePromoCodeClick = () => {
    // Action to perform when Promo Code is clicked
    navigate("/user/promocode");
  };
  const handleDepositClick = () => {
    // Action to perform when Promo Code is clicked
    navigate("/user/deposits");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <p className="text-1xl font-semibold mb-3">iNyumba: My Apartment</p>
        <div className="flex items-center justify-between p-4 bg-white-600 text-black">
          {/* Profile Icon */}
          <div className="relative">
            <div className="cursor-pointer" onClick={handleProfileClick}>
              <FontAwesomeIcon icon={faUser} className="text-3xl" />
            </div>
          </div>

          {/* Notifications Icon */}
          <div className="relative">
            <FontAwesomeIcon
              icon={faBell}
              className="text-3xl cursor-pointer"
              onClick={toggleNotifications}
            />
            {notificationsCount > 0 && (
              <span className="absolute top-0 right-0 text-xs bg-red-600 text-white rounded-full w-4 h-4 flex items-center justify-center">
                {notificationsCount}
              </span>
            )}
            {/* Notifications Dropdown */}
            {isNotificationsOpen && (
              <div className="absolute top-10 right-0 bg-white text-black p-4 rounded-md">
                {notifications.map((notification) => (
                  <div key={notification.notificationid}>
                    {notification.notification}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Logout Icon */}
          <div className="cursor-pointer" onClick={handleLogoutClick}>
            <FontAwesomeIcon icon={faSignOutAlt} className="text-3xl" />
          </div>
        </div>
        <h1 className="text-1xl font-semibold text-black-800">
          Hey {userName}, Welcome
        </h1>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
          {[
            {
              icon: faMoneyBill,
              label: "Bill Payments",
              onClick: handleBillPaymentsClick,
            },
            {
              icon: faHandHoldingUsd,
              label: "House Hunting Fee",
              onClick: handleAgencyFeeClick,
            },
            {
              icon: faArrowDown,
              label: "Withdrawals",
              onClick: handleWithdrawalsClick,
            },
            { icon: faTag, label: "PromoCode", onClick: handlePromoCodeClick },
            { icon: faArrowUp, label: "Deposits", onClick: handleDepositClick },
            {
              icon: faHome,
              label: "My Apartment",
              onClick: handleReservationClick,
            },
            {
              icon: faWrench,
              label: "Maintenance Requests",
              onClick: handleMaintenanceRequestClick,
            },
            {
              icon: faDoorOpen,
              label: "Vacating",
              onClick: handleVacatingClick,
            },
            { icon: faTint, label: "WaterBill", onClick: handleWaterbillClick },
            {
              icon: faFileInvoice,
              label: "Settlement Records",
              onClick: handleSettlementRecords,
            },
            {
              icon: faHistory,
              label: "Previous Reservations",
              onClick: handlePreviousReservationsClick,
            },
          ].map(({ icon, label, onClick }, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center bg-blue-600 text-white shadow-lg rounded-lg p-6 cursor-pointer hover:bg-blue-700 transition-transform transform hover:scale-105"
              onClick={onClick}
            >
              <div className="flex items-center justify-center w-20 h-20 bg-blue-500 rounded-full mb-3 shadow-md">
                <FontAwesomeIcon icon={icon} className="text-4xl" />
              </div>
              <div className="text-center text-lg font-semibold">{label}</div>
            </div>
          ))}
        </div>
      </div>
    </AuthGuard>
  );
};

export default MyApartment;
