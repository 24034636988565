import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { useNavigate } from "react-router-dom";

const SpecificPropertyPage = () => {
  const [propertyInfo, setPropertyInfo] = useState({});
  const [amenities, setAmenities] = useState([]);
  const [rentals, setRentals] = useState([]);
  const [propertyRatings, setPropertyRatings] = useState(null); // State to hold property ratings
  const token = localStorage.getItem("token");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;
  const navigate = useNavigate();
  const propertyId = localStorage.getItem("specificPropertyId");

  useEffect(() => {
    // Fetch property information
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/properties/records/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setPropertyInfo(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching property information:", error);
      });

    // Fetch amenities
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/amenities/fetch/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setAmenities(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching amenities:", error);
      });

    // Fetch rentals
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/rentals/property/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setRentals(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching rentals:", error);
      });

    // Fetch property ratings
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/comments/average-ratings/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setPropertyRatings(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching property ratings:", error);
      });
  }, [propertyId, token]);

  const handleRentalClick = (RentalId) => {
    localStorage.setItem("specificRentalId", RentalId);
    navigate("/user/specificrental");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        {/* Property Information */}
        <div className="bg-white shadow-lg rounded-lg p-6 max-w-3xl mx-auto">
          {/* Property Title */}
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            {propertyInfo.title}
          </h1>

          {/* Property Image */}
          <img
            src={propertyInfo.photo}
            alt={propertyInfo.title}
            className="w-full h-56 object-cover rounded-lg mb-4"
          />

          {/* Property Details */}
          <div className="space-y-2 text-gray-700">
            <p>
              <strong className="font-semibold">Description:</strong>{" "}
              {propertyInfo.description}
            </p>
            <p>
              <strong className="font-semibold">County:</strong>{" "}
              {propertyInfo.county}
            </p>
            <p>
              <strong className="font-semibold">Location:</strong>{" "}
              {propertyInfo.location}
            </p>

            {/* Property Ratings */}
            <p>
              <strong className="font-semibold">Average Rating:</strong>
              <span
                className={`ml-1 ${
                  propertyRatings ? "text-green-600 font-bold" : "text-gray-500"
                }`}
              >
                {propertyRatings ? `${propertyRatings}/5` : "No ratings yet"}
              </span>
            </p>
          </div>
        </div>

        {/* Rentals */}
        <div className="mt-8">
          <div className="max-w-5xl mx-auto p-6">
            {/* Rentals Section */}
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Rentals</h2>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {rentals.map((rental) => (
                <div
                  key={rental.rental_id}
                  className="bg-white shadow-md rounded-lg p-5 cursor-pointer hover:shadow-lg transform hover:scale-105 transition-all duration-300"
                  onClick={() => handleRentalClick(rental.rental_id)}
                >
                  {/* Rental Image */}
                  <img
                    src={rental.photo}
                    alt={rental.property_name}
                    className="w-full h-48 object-cover rounded-md mb-3"
                  />

                  {/* Rental Info */}
                  <h3 className="text-lg font-semibold text-gray-900 mb-1">
                    {rental.property_name}
                  </h3>
                  <p className="text-gray-700 text-sm mb-1">
                    {propertyInfo.description}
                  </p>
                  <p className="text-gray-700 text-sm">
                    <strong>House Type:</strong> {rental.house_type}
                  </p>
                  <p className="text-gray-700 text-sm">
                    <strong>Rent Price:</strong> {rental.rent_price}
                  </p>
                  <p className="text-gray-700 text-sm">
                    <strong>Location:</strong> {propertyInfo.location}
                  </p>
                </div>
              ))}
            </div>

            {/* Amenities Section */}
            <h2 className="text-2xl font-bold text-gray-900 mt-10 mb-4">
              Amenities
            </h2>

            <div className="flex flex-wrap gap-2">
              {amenities.map((amenity, index) => (
                <span
                  key={index}
                  className="bg-gray-100 text-gray-800 text-sm font-medium px-3 py-1 rounded-md"
                >
                  {amenity.amenity_name}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </AuthGuard>
  );
};

export default SpecificPropertyPage;
