import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faStore } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import axios from "axios";

const FirstScreen = () => {
  // Retrieve user data from localStorage
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userName = userData?.fullName || "User";
  const userId = userData.unique;
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  // Determine the greeting based on the time of day
  const hours = new Date().getHours();
  const greeting =
    hours < 12
      ? "Good Morning"
      : hours < 18
      ? "Good Afternoon"
      : "Good Evening";

  // Handlers
  const handleMyApartmentClick = () => {
    navigate("/user/myapartment");
  };

  const handleMarketplaceClick = async () => {
    try {
      const response = await axios.get(
        `https://backend.inyumba.co.ke/iNyumba/user/agencyviewingfee/checkagencyhuntingstatus/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const message = response.data.response;

      if (message === "You have already paid. No need to pay again.") {
        toast.success("You are now able to hunt for rentals.");
        navigate("/user/propertymarketplace");
      } else if (message === "Please pay the propertyhuntingfee.") {
        toast.info(message);
        navigate("/user/agencyviewingfee");
      } else {
        toast.error("Unexpected response. Redirecting to home.");
        navigate("/user/myapartment");
      }
    } catch (error) {
      console.error("Error checking agency hunting status:", error);
      toast.error("An error occurred. Redirecting to home.");
      navigate("/user/myapartment");
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-6 md:p-12">
      {/* Greeting Section */}
      <div className="text-center mb-8">
        <h1 className="text-3xl font-semibold text-gray-800">
          {greeting}, {userName}
        </h1>
      </div>

      {/* Grid Container for Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-lg">
        {/* My Apartment Card */}
        <div
          className="bg-blue-600 text-gray-50 flex flex-col items-center justify-center h-32 rounded-lg shadow-lg cursor-pointer hover:bg-blue-700 active:scale-95 transition transform"
          onClick={handleMyApartmentClick}
        >
          <FontAwesomeIcon icon={faBuilding} className="text-4xl mb-2" />
          <h2 className="text-xl font-medium">My Apartment</h2>
        </div>

        {/* Property Marketplace Card */}
        <div
          className="bg-green-600 text-gray-50 flex flex-col items-center justify-center h-32 rounded-lg shadow-lg cursor-pointer hover:bg-green-700 active:scale-95 transition transform"
          onClick={handleMarketplaceClick}
        >
          <FontAwesomeIcon icon={faStore} className="text-4xl mb-2" />
          <h2 className="text-xl font-medium">Property Marketplace</h2>
        </div>
      </div>
    </div>
  );
};

export default FirstScreen;
